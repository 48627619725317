import contactUser from "@/services/contactUser";
import { getFilterQueryStringWithoutArray, isObject, isEmptyObjectCheck } from "@/helpers/helper.js";
import router from "../router/index";

const getDefaultState = () => {
    return {
        contactList: [],
        userTypes: [],
        contactType: [],
        contactUsers: [],
    };
}
const state = getDefaultState();

const getters = {
    contactList: state => state.contactList.length ?
        state.contactList.map(val => ({
            code: val?.contact_id,
            label: val?.contact?.name
        })) : [],
    userTypes: state => state.userTypes.length ?
        state.userTypes.map(val => ({
            code: val.id,
            label: val?.display_name
        })) : [],
    contactType: state => state.contactType.length ?
        state.contactType.map(val => ({
            code: val?.contact_type_id,
            label: val?.contact_type
        })) : [],
    contactUsers: state => state.contactUsers,
}

const mutations = {
    ['SET_CONTACT_LIST'](state, payload) {
        state.contactList = payload;
    },
    ['SET_USER_TYPES'](state, payload) {
        state.userTypes = payload;
    },
    ['SET_CONTACT_TYPE'](state, payload) {
        state.contactType = payload;
    },
    ['SET_CONTACT_USERS'](state, payload) {
        state.contactUsers = payload;
    }
}

const actions = {
    initContactFetch: ({ state, getters, commit, dispatch }) => {
        const {
            getCustomerId,
            getUser,
            userTypes,
            getLocationCountryInfo,
            contactType,
            getOrgID,
            getIsChildUser,
            getOrgIDFromAccessToken,
        } = getters;
        commit("SET_FETCHING", true);
        let appendActions = [];
        if (!(isObject(getUser) && !isEmptyObjectCheck(getUser))) {
            appendActions = [...appendActions, dispatch("fetchUser")];
        }
        if (!userTypes.length) {
            appendActions = [...appendActions, dispatch("fetchUserType")];
        }
        if (!getLocationCountryInfo.length) {
            appendActions = [...appendActions, dispatch("locationCountryInfo")];
        }
        if (!contactType.length) {
            appendActions = [...appendActions, dispatch("fetchContactType")];
        }
        appendActions = [...appendActions, dispatch("fetchDepartments", { organisation_id: getOrgID })];
        if (getCustomerId) {
            let payload = { customer_uid: getCustomerId };
            if (getIsChildUser)
                payload = { ...payload, organisation_id: getOrgIDFromAccessToken }
            appendActions = [...appendActions, dispatch("fetchOrganizationId", payload)];
        }
        return Promise.all(appendActions).then(async res => {
            commit("SET_FETCHING", false);
        })
    },
    fetchContactList: ({ state, getters, commit, dispatch }) => {
        const { getCustomerId: customer_uid, getOrgID: organisation_uid } = getters;
        return contactUser
            .fetchContact({ customer_uid, organisation_uid })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_LIST", data);
                return res;
            })
            .catch(err => {
                console.log("Error While Fetch Contact List", err);
                return err;
            })
    },
    fetchUserType: ({ getters, commit, dispatch }) => {
        return contactUser
            .fetchUserType()
            .then(res => {
                const { data } = res;
                commit("SET_USER_TYPES", data);
                return res;
            })
            .catch(err => {
                console.log('Error While Fetch UserType', err);
                return err;
            })
    },
    fetchContactType: ({ commit }) => {
        return contactUser
            .fetchContactType()
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_TYPE", data);
                return res;
            })
            .catch(err => {
                console.log('Error While Fetch Contact Type', err);
                return err;
            })
    },
    createUserExisting: ({ getters, commit, dispatch }, data) => {
        const { contact_id, payload: { customer_user_type_id } } = data;
        const { getCustomerId: customer_id, getOrgID: organisation_id } = getters;
        dispatch("showToast", { message: 'Adding...' });
        let queryPayload = {};
        if (contact_id)
            queryPayload = { ...queryPayload, contact_id };
        if (customer_user_type_id)
            queryPayload = { ...queryPayload, customer_user_type_id }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return contactUser
            .createUserExisting({ contact_id, organisation_id, customer_id, queryString })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'User Added Successfully' })
                router.push("/organisation-list");
                return res
            })
            .catch(err => {
                const message = "Error While Create User";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    createUserNew: ({ getters, commit, dispatch }, payload) => {
        const { getCustomerId: customer_id, getOrgID: org_id } = getters;
        let organisation_id = org_id;
        if (payload?.organisation_id)
            organisation_id = payload?.organisation_id
        let { customer_users, customer_recruiters, ...rest } = payload
        dispatch("showToast", { message: 'Adding...' });
        return contactUser
            .createUserNew({ organisation_id, customer_id, payload: { ...rest } })
            .then(res => {
                const { data } = res;
                const { user_id, contact_id, created_by } = data;
                let updatePayload = {
                    user_id,
                    contact_id,
                }
                if (customer_users && customer_users.length) {
                    customer_users = customer_users.map(v => ({
                        user_id,
                        customer_user_type_id: v,
                        created_by
                    }))
                    updatePayload = { ...updatePayload, customer_users }
                }
                if (customer_recruiters && customer_recruiters.length) {
                    customer_recruiters = customer_recruiters.map(v => ({
                        user_id,
                        recruitment_type_id: 1,
                        created_by
                    }))
                    updatePayload = { ...updatePayload, customer_recruiters }
                }
                return contactUser
                    .updateCustomerContactUser({ customer_id, organisation_id, payload: updatePayload })
                    .then(res => {
                        const { data } = res;
                        dispatch("clearAllToast");
                        dispatch("showToast", { class: 'bg-success text-white', message: 'User Added Successfully' })
                        router.push("/list-contactuser");
                        return data;
                    })
                    .catch(err => {
                        const message = "Error While Update User"
                        dispatch("clearAllToast");
                        dispatch("showToast", { class: 'bg-danger text-white', message })
                        console.log(message, err)
                        return err;
                    })
            })
            .catch(err => {
                const message = "Error While Create User";
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err);
                return err;
            })
    },
    fetchContactUser: ({ commit, getters }) => {
        const { getCustomerId: customer_uid, getOrgID: organisation_uid } = getters;
        commit("SET_IS_CUSTOMER_FETCH", true);
        return contactUser
            .fetchContactUser({ customer_uid, organisation_uid })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_USERS", data);
                return res;
            })
            .catch(err => {
                console.log('Error while Fetch Contact User', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_CUSTOMER_FETCH", false);
            })
    },
    updateCustomerUser: ({ dispatch, getters }, payload) => {
        const { getCustomerId: customer_id, getOrgID: organisation_id } = getters;
        dispatch("showToast", { message: 'Updating...' });
        return contactUser
            .updateCustomerUser({ customer_id, organisation_id, payload })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'User Updated Successfully' })
                return data;
            })
            .catch(err => {
                const message = "Error While Update User"
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },
    fetchCustomerContactUser({ commit, getters }, payload = {}) {
        const { getCustomerId: customer_uid, getOrgID: organisation_uid } = getters;
        commit("SET_IS_CUSTOMER_FETCH", true);
        const query = getFilterQueryStringWithoutArray(payload);
        return contactUser
            .fetchCustomerContactUser({ customer_uid, query })
            .then(res => {
                const { data } = res;
                commit("SET_CONTACT_USERS", data);
                return res;
            })
            .catch(err => {
                console.log('Error while Fetch Contact User', err);
                return err;
            })
            .finally(res => {
                commit("SET_IS_CUSTOMER_FETCH", false);
            })
    },
    updateCustomerContactUser: ({ dispatch, getters }, data) => {
        const { getCustomerId: customer_id } = getters;
        dispatch("showToast", { message: 'Updating...' });
        const { organisation_id, payload } = data
        return contactUser
            .updateCustomerContactUser({ customer_id, organisation_id, payload })
            .then(res => {
                const { data } = res;
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-success text-white', message: 'User Updated Successfully' })
                return data;
            })
            .catch(err => {
                const message = "Error While Update User"
                dispatch("clearAllToast");
                dispatch("showToast", { class: 'bg-danger text-white', message })
                console.log(message, err)
                return err;
            })
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
};